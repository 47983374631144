import api from '@/base/utils/request';

// 列表数据
export const indexList = data => {
  return api({
    url: '/admin/cyc_circle/comment/index',
    method: 'post',
    data
  });
};
// 回复
export const getReply = data => {
  return api({
    url: '/admin/cyc_circle/comment/reply',
    method: 'post',
    data
  });
};
// 评论详情
export const getDetail = data => {
  return api({
    url: '/admin/cyc_circle/comment/detail',
    method: 'post',
    data
  });
};
//评论审核接口
export const getIsCheck = data => {
  return api({
    url: '/admin/cyc_circle/comment/isCheck',
    method: 'post',
    data
  });
};
//修改运营数据接口
export const getUpdateVirtual = data => {
  return api({
    url: '/admin/cyc_circle/comment/updateVirtual',
    method: 'post',
    data
  });
};
//加入黑名单接口
export const getIsBlack = data => {
  return api({
    url: '/admin/cyc_circle/comment/isBlack',
    method: 'post',
    data
  });
};
//移除黑名单接口
export const getDelBlack = data => {
  return api({
    url: '/admin/cyc_circle/comment/delBlack',
    method: 'post',
    data
  });
};
//软删
export const Delete = data => {
  return api({
    url: '/admin/cyc_circle/comment/del',
    method: 'post',
    data
  });
};
//硬删
export const softDelete = data => {
  return api({
    url: '/admin/cyc_circle/comment/softDelete',
    method: 'post',
    data
  });
};
//还原
export const getPutBack = data => {
  return api({
    url: '/admin/cyc_circle/comment/putBack',
    method: 'post',
    data
  });
};
//内容查看评论接口
export const getContentAll = data => {
  return api({
    url: '/admin/cyc_circle/comment/ContentAll',
    method: 'post',
    data
  });
};
