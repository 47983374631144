<template>
  <div v-loading="loading">
    <div class="list-content">
      <div class="card-table">
        <div class="table-title">所属内容</div>
        <div class="table-content">
          <div>
            {{detail.contentText ? detail.contentText.content: '-'}}
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="list-content">
      <p class="list-title">评论详情</p>
      <el-row :gutter="20">
        <el-col :span="8">
          <div>评论者 : {{detail.nickname ? detail.nickname : '-'}}</div>
        </el-col>
        <el-col :span="8">
          <div>架构/会内职务 : {{ detail.title_name ? detail.title_name : '-'}}</div>
        </el-col>
        <el-col :span="8">
          <div>审核状态 : <span class="dot"
              :class="detail.is_check === 0 ? 'yellow-dot': (detail.is_check === 1 ? 'gray-dot' : 'green-dot')"></span>{{detail.check_text}}
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <div>内容ID : {{detail.target_id}}</div>
        </el-col>
        <el-col :span="8">
          <div>评论ID : {{ detail.id}}</div>
        </el-col>
        <el-col :span="8">
          <div>评论者IP : {{detail.user_ip}}</div>
        </el-col>
      </el-row>
      <div class="list-title">评论数据</div>
      <el-table :data="tableData" border stripe class="thead-light">
        <el-table-column label="举报数" prop="complaints_count">
          <template slot-scope="scope">
            {{scope.row.complaints_count}}
            <el-button v-if="scope.row.complaints_count > 0" type="text" @click="checkReportRecode(id)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="回复数" prop="reply"></el-table-column>
        <el-table-column label="用户点赞数" prop="like"></el-table-column>
        <el-table-column label="运营点赞数" prop="virtual_like">
          <template slot-scope="scope">
            <el-input v-model="scope.row.virtual_like" type="number" min="0"></el-input>

          </template>
        </el-table-column>
        <el-table-column label="评论时间" prop="time"></el-table-column>
      </el-table>
      <div class="list-title">评论内容</div>
      <div class="card-table" style="margin-top: 24px;">
        <div class="table-title">内容详情</div>
        <div class="table-content">
          <div>{{detail.content}}</div>
          <div></div>
        </div>
      </div>
    </div>
    <FixedActionBar>
      <el-button type="primary" size="medium" @click="save">保存
      </el-button>
      <el-button size="medium" @click="cancelConfig">返回</el-button>
    </FixedActionBar>
    <!-- 举报记录弹窗 -->
    <ReportRecodeDialog v-model="showReportRecodeDialog" :requestData="ReportRecodeRequest"/>
  </div>
</template>

<script>
import FixedActionBar from "@/base/layout/FixedActionBar";
import ReportRecodeDialog from "@/modules/common/components/ReportRecodeDialog";
import { getDetail, getUpdateVirtual } from "../api/circle-comment";

export default {
  components: {
    FixedActionBar,
    ReportRecodeDialog,
  },
  data() {
    return {
      loading: false,
      detail: [],
      tableData: [],
      id: 0,
      showReportRecodeDialog: false, //是否显示举报记录弹窗
      ReportRecodeRequest: {
        page: 1,
        page_size: 15,
        data_type: "comment", // 举报数据类型
        data_table: "cyc_circle_comment", // 举报数据表
        data_id: "", // 举报内容id
      },
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.requestGetDetail();
  },
  methods: {
    // 查看举报记录
    checkReportRecode(id) {
      this.ReportRecodeRequest.data_id = id;
      this.showReportRecodeDialog = true;
    },
    requestGetDetail() {
      this.loading = true;
      getDetail({ id: this.id })
        .then((res) => {
          this.detail = res.data;
          this.tableData.push({
            complaints_count: this.detail.complaints_count,
            reply: this.detail.replies_count,
            like: this.detail.likes_count,
            virtual_like: this.detail.praise_count,
            time: this.detail.create_time,
          });
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    cancelConfig() {
      window.history.go(-1);
    },
    save() {
      getUpdateVirtual({
        id: this.id,
        praise_count: Number(this.tableData[0].virtual_like),
      })
        .then((res) => {
          this.$message.success("保存成功");
        })
        .catch((err) => {});
      window.history.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-content {
  .card-table {
    border: 1px solid #e9e9e9;
    border-radius: 4px;
  }

  .list-title {
    padding: 59px 4px 19px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #e9e9e9;
  }

  .table-title {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 14px;

    padding: 15px 24px;
    background: rgba(250, 250, 250, 1);
    border-bottom: 1px solid #e9e9e9;
  }

  .el-table {
    margin: 24px 4px 0;
  }
  .table-content {
    padding: 16px 24px;
  }
  .el-row {
    margin-top: 20px;
    padding: 0 4px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }

  .yellow-dot {
    background: rgba(255, 203, 44, 1);
  }

  .green-dot {
    background: rgba(19, 206, 102, 1);
  }

  .gray-dot {
    background: rgba(144, 147, 153, 1);
  }
  .user_ip {
    height: 50px;
  }
}
</style>
